import { FC, useState } from 'react';
import Button from '../../components/Button';
import Form from '../../components/Form';
import classnames from 'classnames';
import CheckMark from '../../assets/icons/CheckMark';
import { isValidEmail } from '../../utils/validators';
import { Controller, useForm } from 'react-hook-form';
import countries from '../../utils/data/countries.json';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ProfileFormProps = {
  profile: any;
  onUpdate: (data: any) => void;
  title?: string;
  isUpdate?: boolean;
};

const ProfileForm = ({
  profile,
  onUpdate,
  title = 'profile_form__title',
  isUpdate = false,
}: ProfileFormProps) => {
  const [formData, setFormData] = useState(profile);
  const [isValidForm, setIsValidForm] = useState(isUpdate);
  const { handleSubmit, control, register } = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gender = [
    {
      value: 'male',
      label: t('profile_form__input_gender_male'),
      defaultChecked: profile.gender == 'male',
    },
    {
      value: 'female',
      label: t('profile_form__input_gender_female'),
      defaultChecked: profile.gender == 'female',
    },
  ];

  function toProfileDetail() {
    navigate('/home');
  }

  const minimumAgeDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18),
  )
    .toISOString()
    .split('T')[0];

  return (
    <div className="">
      <div className="relative max-h-screen h-screen px-6 grid max-w-[800px] mx-auto">
        <div className="w-full">
          <div className="border-b-2 my-6">
            <h1 className="text-title text-2xl mb-4 text-center font-medium sm:text-start text-dark">
              {t(title)}
            </h1>
            <p className="text-text text-center mb-6 text-gray-dark font-lighter sm:text-start">
              {t('profile_form__description')}
            </p>
          </div>
          <form
            className="w-full flex flex-col my-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase text-start">
              {t('profile_form__subtitle_personal_data')}
            </h3>

            <Form.Field
              type="text"
              defaultValue={formData?.first_name}
              name="first_name"
              label={t('profile_form__input_first_name')}
              placeholder={t('profile_form__input_first_name')}
              errors={!formData.first_name}
              onChange={textFieldListener}
              register={register}
              //  readOnly={profile?.first_name}
            />
            <Form.Field
              type="text"
              defaultValue={formData?.last_name}
              name="last_name"
              label={t('profile_form__input_last_name')}
              placeholder={t('profile_form__input_last_name')}
              errors={!formData.last_name}
              onChange={textFieldListener}
              register={register}
              //  readOnly={profile?.last_name}
            />
            <Form.Field
              type="text"
              defaultValue={formData?.billing.tax_id}
              name="tax_id"
              label={t('profile_form__input_tax_id')}
              placeholder={t('profile_form__input_tax_id')}
              errors={!formData.billing?.tax_id}
              onChange={textFieldListener}
              register={register}
              // readOnly={profile?.tax_id}
            />
            <Form.Date
              type="date"
              defaultValue={formData?.birth_date}
              name="birth_date"
              label={t('profile_form__input_birth_date')}
              placeholder={t('profile_form__input_birth_date')}
              errors={!formData.birth_date}
              onChange={textFieldListener}
              max={minimumAgeDate}
              // readOnly={profile?.birth_date}
            />
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase text-start">
              {t('profile_form__subtitle_gender')}
            </h3>
            <div className="flex mb-2 space-x-4">
              {gender.map(({ label, value }) => (
                <RadioButton
                  key={value}
                  name="gender"
                  label={label}
                  value={value}
                  checked={formData.gender === value}
                  onClick={checkboxListener}
                  //  readOnly={profile?.gender}
                  //  disabled={profile?.gender}
                  errors={!formData.gender}
                />
              ))}
            </div>
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase text-start">
              {t('profile_form__subtitle_contact')}
            </h3>
            <Form.Field
              type="email"
              defaultValue={formData?.email}
              name="email"
              label={t('profile_form__input_email')}
              placeholder={t('profile_form__input_email')}
              errors={!isValidEmail(formData?.email ?? '')}
              onChange={textFieldListener}
              register={register}
            />
            <div className="flex">
              <Dropdown
                defaultValue={
                  profile?.phone_prefix
                    ? profile?.phone_prefix
                    : formData?.phone_prefix
                    ? '+' + formData?.phone_prefix
                    : '+34'
                }
                name={'phone_prefix'}
                control={control}
                options={countries.map(({ prefix, name }: any) => ({
                  value: prefix,
                  label: `${name} (${prefix})`,
                }))}
                onChange={prefixListener}
                readOnly={profile?.phone_prefix}
              />
              <Form.Field
                type="tel"
                defaultValue={formData?.phone}
                label={t('profile_form__input_phone')}
                placeholder={t('profile_form__input_phone')}
                name="phone"
                errors={!formData.phone}
                onChange={textFieldListener}
                readOnly={profile?.phone}
                register={register}
              />
            </div>
            <Button type="submit" className="mt-3" disabled={!isValidForm}>
              {t('profile_form__button_confirm')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  function checkboxListener(event: any) {
    let newData = {
      ...formData,
      gender: event.target.value,
    };
    //  setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function prefixListener(prefix: any) {
    let newData = {
      ...formData,
      phone_prefix: prefix,
    };
    // setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function textFieldListener(event: any) {
    let newData = {
      ...formData,
    };

    switch (event.target.name) {
      case 'first_name':
        newData.first_name = event.target.value;
        break;
      case 'last_name':
        newData.last_name = event.target.value;
        break;
      case 'tax_id':
        newData.billing.tax_id = event.target.value;
        break;
      case 'birth_date':
        newData.birth_date = event.target.value;
        break;
      case 'email':
        newData.email = event.target.value;
        break;
      case 'phone':
        newData.phone = event.target.value;
        break;
      default:
        break;
    }
    // setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function onSubmit() {
    onUpdate({
      formData: {
        ...formData,
        phone_prefix: formData['phone_prefix']
          ? formData['phone_prefix']
          : '+34',
      },
      closure: () => {
        toProfileDetail();
      },
    });
  }

  function isFormValid(profile: any): boolean {
    return !(
      !profile.first_name ||
      !profile.last_name ||
      !isValidEmail(profile.email ?? '') ||
      !profile.birth_date ||
      !profile.gender ||
      !profile.phone ||
      !profile.billing.tax_id
    );
  }
};

// Components
const RadioButton = ({
  name,
  checked,
  label,
  value,
  register,
  disabled,
  defaultChecked,
  onChange,
  onClick,
  readOnly,
  errors,
}: any) => (
  <label
    className={classnames(
      'flex justify-between font-lighter relative group cursor-pointer w-full px-4 py-4 text-center border rounded-lg',
      {
        'hover:border-primary': !readOnly,
        'border-primary bg-blue-light profile-form-checked': checked,
        'border-separators': !checked,
        'border-negative': !!errors,
      },
    )}
  >
    <input
      className="hidden"
      name={name}
      type="radio"
      disabled={disabled}
      value={value}
      ref={register}
      defaultChecked={defaultChecked}
      onClick={onClick}
      readOnly={readOnly}
    />

    <div
      className={classnames({
        'text-dark': !checked,
        'text-primary': checked,
      })}
    >
      {label}
    </div>
    {checked ? (
      <CheckMark
        className={classnames('mr-0 group-hover:text-primary', {
          'text-gray-dark': !checked,
          'text-primary': checked,
        })}
      />
    ) : (
      <div></div>
    )}
    <div
      className={classnames('absolute inset-0 w-full h-full opacity-10', {
        'bg-blue-light': checked,
      })}
    />
  </label>
);

const Dropdown: FC<any> = ({
  name,
  options,
  defaultValue,
  control,
  onChange,
  readOnly,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange: onChangeRender, value, name } }) => (
      <Select
        className={classnames('Dropdown prefix w-28')}
        styles={{
          menu: (provided: any) => ({ ...provided, width: '250px' }),
          singleValue: (base: any) => ({ ...base }),
        }}
        components={{ SingleValue }}
        isSearchable={true}
        classNamePrefix="ReactDropdown"
        options={options}
        value={options.find((o: any) => o.value === value)}
        name={name}
        defaultValue={options.find((o: any) => o.value === defaultValue)}
        onChange={(selectedOption: any) => {
          onChange(selectedOption.value);
          onChangeRender(selectedOption.value);
        }}
        isDisabled={readOnly}
      />
    )}
  />
);

const SingleValue: FC<any> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {children.match(/\((.*)\)/)[1]}
  </components.SingleValue>
);

export default ProfileForm;
