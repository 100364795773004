import axios from 'axios';
import { config } from '../config/config';
import getHeaders from '../utils/getHeaders';
import { browserName } from 'react-device-detect';
import { appointmentsApi } from './appointments';

const BASE_URL = config.mediquo.api;
const PATH = 'api/v1';
const CHAT_URL = config.mediquo.chatApi;

export type SendCodeProps = {
  prefix: string;
  phone: string;
  channel: 'sms' | 'whatsapp';
  token?: string | null;
};

export type RegisterProps = {
  sessionId: string | null;
};

export type VerifyCodeProps = {
  code: string;
  prefix: string;
  number: string | undefined;
};

export type VerifyCouponProps = {
  code: string;
};

export type UpdateProfileRQ = {
  formData: string;
};

const register = ({ sessionId }: RegisterProps) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}api/installations`,
    headers: getHeaders(),
    data: {
      installation_guid: sessionId,
      language_code: navigator.language,
      os_name: navigator.platform,
      os_version: navigator.appVersion,
      app_version: 'lite',
      device_model: browserName,
      device_id: sessionId,
      brand: navigator.vendor,
    },
  });

const sendCode = ({ token, prefix, phone, channel }: SendCodeProps) =>
  axios({
    method: 'post',
    url: `${BASE_URL}${PATH}/login-${channel}`,
    data: {
      phone_prefix: prefix,
      installation_guid: localStorage.getItem('sessionId'),
      phone,
      token,
    },
    headers: getHeaders(),
  });

const verifyCode = ({ code, prefix, number }: any) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${PATH}/check`,
    headers: getHeaders(),
    data: {
      phone: number,
      phone_prefix: prefix,
      installation_guid: localStorage.getItem('sessionId'),
      language_code: navigator.language,
      verification_code: code,
    },
  });

const verifyCoupon = ({ code }: VerifyCouponProps) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${PATH}/coupon-subscription`,
    headers: getHeaders(),
    data: {
      code,
    },
  });

const socialLogin = ({ accessToken, provider }: any) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${PATH}/auth/login/web-social`,
    headers: getHeaders(),
    data: {
      access_token: accessToken,
      installation_guid: localStorage.getItem('sessionId'),
      provider,
    },
  });

const profile = () =>
  axios({
    method: 'GET',
    url: `${CHAT_URL}profile/v1/patients`,
    headers: getHeaders(),
  });

const updateProfile = ({ formData }: UpdateProfileRQ) =>
  axios.put(`${CHAT_URL}profile/v1/patients`, formData, {
    headers: getHeaders(),
  });

export default {
  register,
  sendCode,
  verifyCode,
  verifyCoupon,
  socialLogin,
  profile,
  updateProfile,

  appointments: appointmentsApi,
};
